<template>
  <b-row align-h="center">
    <b-col
      md="6"
      class="mt-5"
    >
      <b-card header="ResetPassword">
        <validation-observer #default="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(resetPassword)">
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  placeholder="email@example.com"
                  :state="getValidationState(validationContext)"
                />
                <b-form-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-feedback>
              </b-form-group>
            </validation-provider>
            <b-button
              variant="primary"
              type="submit"
            >
              Reset
            </b-button>
            <back />
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import formValidation from '@/@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'ResetPassword',
  components: { Back },
  data() {
    return {
      email: '',
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const { successfulOperationAlert, showErrors } = handleAlerts()
    return {
      getValidationState,
      successfulOperationAlert,
      showErrors,
    }
  },
  methods: {
    resetPassword() {
      this.$portalUsers.post('v1/password/request/reset', { email: this.email }).then(() => {
        this.successfulOperationAlert('Reset password email is sent successfully. Please check your email')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
  },
}
</script>
<style lang="scss">

</style>
